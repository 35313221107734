<div>
  <app-header [title]="title"></app-header>
  <div class="container-md p-3 p-sm-5 mt-3 shadow-lg rounded">
    <div class="row" id="Addressat">
      <div class="col">
        <h4>
          {{ addressant }}
        </h4>
      </div>
    </div>
    <div class="row mt-5" id="Offener-Brief">
      <div class="col" [innerHtml]="_returnHtmlFromRichText(text)"></div>
    </div>
    <div class="row mt-3" id="Kontakt">
      <div
        class="col"
        [innerHtml]="_returnHtmlFromRichText(contactNotice)"
      ></div>
    </div>

    <div class="row align-items-center mt-3">
      <div class="col-12 align-self-center">
        <h4 style="margin-bottom: 0">
          <span *ngIf="signees?.length">{{ signees.length }}</span>
          Organisationen haben unterzeichnet.
        </h4>
      </div>
    </div>
    <div class="row row mt-5 align-items-start" id="Unterzeichner">
      <ng-template ngFor let-signee [ngForOf]="signees">
        <app-signee
          *ngIf="signee && signee?.fields"
          [name]="signee.fields.name"
          [logo]="signee.fields.logo"
          [website]="signee.fields.website"
          [listOfSigningNames]="signee.fields.listOfSigningNames"
          class="col-sm-6 col-md-4 mt-3 text-left"
        >
        </app-signee>
      </ng-template>
    </div>
  </div>
</div>
